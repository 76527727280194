import * as THREE from '/node_modules/three/build/three.module.js';

import Stats from '/node_modules/three/examples/jsm/libs/stats.module.js';

import { OrbitControls } from '/node_modules/three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from '/node_modules/three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from "/node_modules/three/examples/jsm/loaders/GLTFLoader";

class Model3D {

    constructor(container) {
        this.container = container;

        this.init();

        window.addEventListener("resize", () => {
            this.onWindowResize()
        });

        window.addEventListener("click", () => {
            this.playAudio()
        });
        window.addEventListener("touchstart", () => {
            this.playAudio()
        });
    }

    init() {
        this.direction = new THREE.Vector3();
        this.camOffset = 5;

        this.camera = new THREE.PerspectiveCamera( 100, window.innerWidth / window.innerHeight, 1, 2500 );
        this.camera.position.set( 50.0, 0, -400 * 3.5 );

        this.scene = new THREE.Scene();

        this.hdrCubeRenderTarget = null;

        this.loader = new GLTFLoader();
        this.loadGLTF(this.loader);

        this.textureLoader = new THREE.TextureLoader();
        
        const scene = new THREE.Scene();
        this.textureEquirec = new THREE.CubeTextureLoader()
        .load( [
            '/img/hamburgerBahnhofBG_2.jpg',
            '/img/hamburgerBahnhofBG_4.jpg',
            '/img/hamburgerBahnhofBG_0.jpg',
            '/img/hamburgerBahnhofBG_5.jpg',
            '/img/hamburgerBahnhofBG_1.jpg',
            '/img/hamburgerBahnhofBG_3.jpg'
        ] );
        
        this.textureEquirec.encoding = THREE.sRGBEncoding;
        this.scene.background = this.textureEquirec;

        const ambient = new THREE.AmbientLight( 0xffffff, 1); 
        this.scene.add( ambient );

        this.clock = new THREE.Clock();

        this.renderer = new THREE.WebGLRenderer( { antialias: true } );
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.container.appendChild( this.renderer.domElement );

        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1;

        const pmremGenerator = new THREE.PMREMGenerator( this.renderer );
        pmremGenerator.compileEquirectangularShader();

        this.controls = new OrbitControls( this.camera, this.renderer.domElement );
        this.controls.autoRotate = true;
        this.controls.autoRotateSpeed = .7;
        this.controls.minDistance = 10;
        this.controls.maxDistance = 21;
        this.controls.enablePan = false;
		this.controls.enableZoom = true;

        this.currentObject = this.particleLight;

        this.animate();
    }


    onWindowResize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
    
        this.renderer.setSize( window.innerWidth, window.innerHeight );
    }    

    animate() {
        const Model = this;
        requestAnimationFrame(function () {
            Model.animate();
        });
        this.timer = Date.now() * 0.00025;
        
        if (this.obj) this.obj.scene.rotation.y += 0.001;

        this.controls.update();

        var mixerUpdateDelta = this.clock.getDelta();
        if ( this.mixer ) this.mixer.update( mixerUpdateDelta); 

        this.renderer.render(this.scene, this.camera);
    }


    loadGLTF() {

        this.loader.load( "/models/KGS-60-02-TEX-1Mill_2.glb", (object)  =>  {
            this.obj = object;
            this.dummy= this.obj.scene.children[1];


            if (this.currentObject != this.dummy) this.currentObject = this.dummy
            this.obj.scene.traverse((o) => {
                if (o.isMesh ) {
                    o.material = new THREE.MeshPhysicalMaterial( {
                        color: new THREE.Color().setHSL( 1,1,1 ),
                        metalness: 0,
                        roughness: 0.0,
                        clearcoat: 0,
                        clearcoatRoughness: 0,
                        reflectivity: 0.95,
                        map: o.material.map
                    } );
                o.receiveShadow = true;
                }
            });
            this.obj.scene.position.x = 0;
            this.obj.scene.position.y = 0;
            this.obj.scene.position.z = 0;
            this.obj.scene.scale.x = .5;
            this.obj.scene.scale.y = .5;
            this.obj.scene.scale.z = .5;
            this.obj.scene.rotation.y += 90;

            this.mixer = new THREE.AnimationMixer( this.obj.scene );
            this.clips = this.obj.animations;

        
            var action = this.mixer.clipAction(  this.getAnimation(this.obj, "All Animations") );
            this.clip = THREE.AnimationClip.findByName( this.clips, 'All Animations' );
            this.action = this.mixer.clipAction(this.clip);

            this.action.play();
            
            this.scene.add( this.obj.scene);

            this.audio = document.getElementById("audio");
    
         } );
       }

       playAudio() {
        this.audio.play();
        }
    

      getAnimation(gltf, name){
        var result;
        gltf.animations.forEach((animation) => {
            if (animation.name===name) {
                result = animation
                return
            }
        })
        if (result == null) {
            console.error("animation: "+name+" cannot be found!")
        }
        return result
    }
}

export default Model3D