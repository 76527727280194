import Model3D from './Model3D'

let __container = document.getElementById("container");
let __scene = new Model3D(__container)





document.getElementById("infolayer-close").addEventListener("click", (e) => {
    document.body.classList.add("infolayer-hidden")
})